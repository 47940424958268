import { HStack, Text, Image, Container, Stack } from "@chakra-ui/react";
// import WithFriendsLogo from "../assets/companies/withfriends.svg";
import LlamaIndexLogo from "../assets/companies/llamaindex.png";
import GradioLogo from "../assets/companies/gradio.svg";
import PulleyLogo from "../assets/companies/pulley.png";
import HearthLogo from "../assets/companies/hearth.svg";

const data = [
    { logo: PulleyLogo },
    { logo: HearthLogo },
    { logo: GradioLogo },
    { logo: LlamaIndexLogo, name: "LlamaIndex" },
];

export default function Users() {
    return (
        <Container maxWidth={"5xl"} px={0} mt={"16rem"}>
            <Stack alignItems="stretch" width="100%">
                <Text fontSize="3xl" mb={12} textAlign={"left"}>
                    Trusted by organizations including
                </Text>
                <HStack justifyContent="space-between" mb={64} flexWrap="wrap" maxWidth={"5xl"}>
                    {data.map(({ logo, name, ...props }) => (
                        <Image
                            src={logo}
                            alt="placeholder"
                            mx={4}
                            width={{ base: "100%", md: "150px", lg: "200px" }}
                            mb={{ base: 8, md: 0 }}
                            userSelect="none"
                            _hover={{ filter: "none" }}
                            {...props}
                        />
                    ))}
                </HStack>
            </Stack >
        </Container >
    )
}